import http from "../http-common";

class EvaluationService {
    getevas() {
        return http.get('/evaluation');
    }
    geteva(id) {
        return http.get('/evaluation/' + id);
    }
    createeva(data) {
        return http.post('/evaluation', data);
    }
    updateeva(id, data) {
        return http.put(`/evaluation/${id}`, data);
    }
}

export default new EvaluationService();