<template>
  <div id="app">
    <!-- <Nav msg="Welcome to Your Vue.js App" /> -->
    <table width=100%>
      <tr>
        <td width=3%></td>
        <td width=0.1% bgcolor="#ffffff"></td>


        <td width=93.8% bgcolor="#of1527">
          <table width=100%>
            <tr>
              <td width=5%></td>
              <td><br><br><br><br>
                <font size="8" color="#1099f0">ระบบประเมินความพึงพอใจการให้บริการ</font>
                <br><br>
                <font size="10" color="#ffffff">สำนักงานขนส่งจังหวัดเชียงราย</font>

              </td>
            </tr>

            <tr>
              <td width=5%></td>
              <td height=100 valign="top">
                <font color="#9a9eaf">
                  <br><br>การประเมินความพึงพอใจการให้บริการในรูปแบบออนไลน์ผ่านระบบเครือข่ายอินเทอร์เน็ต</font>
              </td>
            </tr>

            <tr>
              <td width=5%></td>
              <td>
                <table width=100% bgcolor="#5472cc">
                  <tr>
                    <td width=33%>
                      <center><a href="https://eva-transport.visdchiangrai.com/"><img src="../src/assets/ass.png" style="width:50%"><br><br> <span style="color:white">ระบบประเมินความพึงพอใจการให้บริการ</span></a></center>
                    </td>

                    <td width=33%></td>
                    <td width=33%></td>
                  </tr>
                </table>

              </td>
            </tr>
          </table>
        </td>



        <td width=0.1% bgcolor="#ffffff"></td>
        <td width=3%></td>


      </tr>

    </table>
    <!-- <router-view /> -->
  </div>
</template>

<script>
// import Nav from './components/Nav.vue'

export default {
  name: 'App',
  components: {
    // Nav
  }
}
</script>

<style>div {
  font-family: 'Niramit', sans-serif;
  font-size: 14px;
}</style>