<template>
<div class="container-fluid">
  <div class="container" v-if="savestatus">
  <div v-if="type == 1">
  <div class="form-group row mt-3">
    <div class="col-sm-10">
      <img class="mt-3" v-if="path_image" :src="path_image" width="50%">
      </div>
</div>
<div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อ-นามสกุล</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.first_last_name}}</label>
    </div>
  </div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ตำแหน่ง</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.position}}</label>
    </div>
  </div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">หน่วยงาน</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.office}}</label>
    </div>
  </div>
</div>
<div v-else>
  <div class="form-group row mt-3">
    <div class="col-sm-10">
      <img class="mt-3" v-if="path_image" :src="path_image" width="50%">
      </div>
</div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อหน่วยงาน</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.name}}</label>
    </div>
  </div>
</div>
<h5 class="mt-3">ความพึงพอใจในการให้บริการ</h5>

<div class="row">
  <div class="col-md-1 mt-3"><div class="form-check">
  <label class="form-check-label" for="exampleRadios5">
    <img src="../assets/5.jpg" id="exampleRadios" style="width:50px">
  </label><br/>
  <input v-model="eva.score" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="5">
</div></div>
  <div class="col-md-1 mt-3"><div class="form-check">
  <label class="form-check-label" for="exampleRadios4">
    <img src="../assets/4.jpg" id="exampleRadios" style="width:50px">
  </label><br/>
  <input v-model="eva.score" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="4">
</div></div>
<div class="col-md-1 mt-3"><div class="form-check">
  <label class="form-check-label" for="exampleRadios3">
    <img src="../assets/3.jpg" id="exampleRadios" style="width:50px">
  </label><br/>
  <input v-model="eva.score" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="3">
</div></div>
<div class="col-md-1 mt-3"><div class="form-check">
  <label class="form-check-label" for="exampleRadios2">
    <img src="../assets/2.jpg" id="exampleRadios" style="width:50px">
  </label><br/>
  <input v-model="eva.score" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="2">
</div></div>
<div class="col-md-1 mt-3"><div class="form-check">
  <label class="form-check-label" for="exampleRadios1">
    <img src="../assets/1.jpg" id="exampleRadios" style="width:50px">
  </label><br/>
  <input v-model="eva.score" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="1">
</div></div>
</div>
<div class="form-group mt-5">
    <label for="exampleFormControlTextarea1">ข้อเสนอแนะ</label>
    <textarea v-model="eva.suggestion" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
  <div class="form-group row mt-3" style="text-align:center">
    <div class="col-sm-12">
      <button @click="save()"
            class="btn btn-success mt-3 mb-3 btn-sm"            
          >
            ส่งผลการประเมิน
          </button>
    </div>
  </div>
</div>
<div class="mt-5" v-else>
  <h6 style="text-align:center">บันทึกผลการประเมินเรียบร้อยแล้ว</h6>
</div>
</div>
</template>

<script>
import IndividualService from '../services/IndividualService'
import OfficeService from '../services/OfficeService'
import EvaluationService from '../services/EvaluationService'

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      type:'',
      statustable:false,
      data:{},
      path_image:'',
      id:'',
      savestatus:true,
      eva:{}
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.type = this.$route.params.type
    this.getdata()
    this.getstatus(this.type)
  },
  methods: {
    getstatus(type){
  this.type = type
  if (this.type == 1) {
      this.statustable = true
    }else{
      this.statustable = false
    }
},
getdata(){
if (this.type == 1) {
  IndividualService.getindividual(this.id).then((res=>{
    console.log(res.data);
    this.data = res.data
    this.path_image = this.data.path_image
  }))
    
}else{
  OfficeService.getoffice(this.id).then((res)=>{
    console.log(res.data);
    this.data = res.data
    this.path_image = this.data.path_image
  })
}
},
save(){
  if (this.eva.score == '' || this.eva.score == null) {
    alert('กรุณาเลือกคะแนนความพึงพอใจในการให้บริการ')
  }else{
    var off = {
      office_id:this.id,
      score:this.eva.score,
      suggestion:this.eva.suggestion,
      type:this.type
    }
    EvaluationService.createeva(off).then((res)=>{
      console.log(res.data);
      this.savestatus = false
    })
  
}
}
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}
.card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}
body{
background-color: gray;
}
input[type=radio] {
  margin-left: 18px;
    box-sizing: border-box;
    padding: 0;
}
</style>
