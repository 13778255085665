
class LinkImageService {
  getLinkImage() {
    // return 'http://localhost:8080/uploadimage?name='
    return 'https://api-eva-transport.visdchiangrai.com/uploadimage?name='
  } 
  getLink() {
    // return 'http://localhost:8080'
    return 'https://api-eva-transport.visdchiangrai.com'
  } 
}

export default new LinkImageService();